<template>
  <div>
    <br />
    <SuccessMoviment v-if="successMoviment" :rentId="rentId" :movimentId="movimentId"
      msgSuccess="Retorno processado com sucesso!" />
    <div v-if="!successMoviment">
      <div class="text-center" v-show="!loading">
        <span class="check">
          <i class="fa-solid fa-clipboard-check"></i>
          Retorno
        </span>
      </div>
      <br />
      <div v-show="loading">
        <Loading type="line" :center="true" />
        <div class="title">Aguarde, estamos preparando o retorno...</div>
      </div>
      <div v-show="!loading">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <Molded>
              <div class="period">Período da Locação</div>
              <div class="period">{{ content.periodRentCurrent }}</div>
            </Molded>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="6">
            <Molded>
              <div class="period side-by-side">Período Final</div>
              <div class="side-by-side div-update">
                <span class="edit" @click="confirmReturn"> Editar </span>
              </div>
              <div class="period">{{ content.periodRentReturn }}</div>
            </Molded>
          </b-col>
        </b-row>
        <br v-if="content.isReturnBefore || content.isReturnAfter" />

        <b-row v-if="content.isReturnBefore || content.isReturnAfter">
          <b-col sm="12">
            <Alert type="warning">
              <span>
                A Locação está sendo retornada
                <b>
                  <DisplayCalculatePeriod :fontSize="16" :periodRent="{
                    year: content.year,
                    month: content.month,
                    day: content.day,
                    hour: content.hour,
                    minute: content.minute,
                  }" />
                </b>
              </span>
              <span v-if="content.isReturnBefore">
                antes da data prevista!&nbsp;&nbsp;
              </span>
              <span v-else> após a data fim!&nbsp;&nbsp; </span>
              <span class="discard" @click="confirmDiscard"> Descartar </span>
            </Alert>
          </b-col>
        </b-row>
        <div class="div-recalculate">
          <Molded v-if="(content.isReturnBefore || content.isReturnAfter) &&
            hasRule('Rental')
            ">
            <div>
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <RadioGroup title="Recalcular Valor do Produto?" field="periodSubtractRecalculate"
                    :changed="recalculate" :options="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false },
                    ]" v-if="content.isReturnBefore" v-model="periodSubtractRecalculate" />

                  <RadioGroup title="Recalcular Valor do Produto?" field="periodAddRecurrent" :changed="recalculate"
                    :options="[
                      { text: 'Sim', value: true },
                      { text: 'Não', value: false },
                    ]" v-if="content.isReturnAfter" v-model="periodAddRecurrent" />
                </b-col>
              </b-row>
            </div>
          </Molded>
        </div>
        <br />
        <Molded v-if="!loading">
          <TableTotalRecords :totalRecords="content.items.length" />
          <ScrollBar :minHeight="0" :maxHeight="600">
            <table class="table table-responsive-xs">
              <thead>
                <tr>
                  <th>
                    <span class="title-header">Produto</span>
                  </th>
                  <th>
                    <span class="title-header">Descrição</span>
                  </th>
                  <th class="text-center">
                    <span class="title-header">Locação</span>
                  </th>
                  <th class="text-center">
                    <span class="title-header">Retorno</span>
                  </th>
                  <th class="text-center">
                    <span class="title-header">Status</span>
                  </th>
                  <!--  <th class="text-center" v-if="hasRule('Rental') || hasRule('PDV')">
                    <span class="title-header">Total</span>
                  </th>-->
                </tr>
              </thead>
              <tbody v-for="item in content.items">
                <tr>
                  <td class="td-product">{{ item.productFullName }}</td>
                  <td>
                    <div class="td-description">
                      <InputTextEdit v-model="item.description" />
                    </div>
                  </td>
                  <td class="text-center td-quantity">{{ item.totalRent }}</td>
                  <td class="text-center td-quantity">
                    {{ item.totalSelected }}
                  </td>
                  <td class="text-center td-quantity">
                    <span class="status-danger" v-if="!item.isValid">
                      <i class="fa-solid fa-do-not-enter"></i>
                    </span>
                    <span class="status-warning" v-if="item.totalSelected < item.totalRent && item.isValid">
                      <div v-if="item.typeProduct != 5">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                      </div>
                      <div v-else>
                        <div>
                          <div v-if="item.totalSelected == 0">
                            <span class="status-danger">
                              <i class="fa-solid fa-do-not-enter"></i>
                            </span>
                          </div>
                          <div v-else>
                            <CheckboxSimple title="Finalizar Retorno" v-model="item.doneMaterial" />
                          </div>
                        </div>
                      </div>
                    </span>
                    <span class="status-success" v-if="item.totalSelected == item.totalRent && item.isValid">
                      <i class="fa-solid fa-circle-check"></i>
                    </span>
                  </td>
                  <!--  <td class="text-center value">
                    {{ item.totalValue | currency }}
                  </td> -->
                </tr>
                <td class="div-patrimony" colspan="8" v-if="item.patrimony.length > 0">
                  <div v-for="patrimony in item.patrimony">
                    <span>
                      <i class="fa-solid fa-circle-arrow-right"></i>
                      {{ patrimony.barCode }}
                    </span>
                  </div>
                </td>
              </tbody>
              <tbody v-show="content.items.length == 0">
                <tr>
                  <td colspan="12">
                    <span>Nenhum produto encontrado!</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </ScrollBar>
        </Molded>
        <br />
        <Alert type="danger" v-if="isStockNegative">
          <span>
            Não é permitido saída de estoque negativo. Verifique e ajuste os produtos em falta.
          </span>
        </Alert>
      </div>
      <div class="text-center">
        <span v-show="!loading">
          <slot></slot>
        </span>
        <Button v-if="!loading" _key="btnGenerateOutput" type="success" :disabled="isStockNegative || isInvalid"
          title="Gerar Retorno" classIcon="fa-solid fa-circle-check" size="large" :clicked="generate" />
      </div>
    </div>
    <Modal title="Tem certeza que deseja descartar?" :width="500" v-show="showModal('discard')">
      <Confirmation :isModal="false" type="danger" :confirmed="discard">
        <Alert type="warning">
          <span>Não será gerado nenhum período adicional na locação. Essa operação é irreversível.</span>
        </Alert>
      </Confirmation>
    </Modal>
    <Modal title="Data Fim" :width="500" v-show="showModal('editReturn')">
      <DateTime title="Fim" field="endDateHourReturn" format="DD/MM/YYYY HH:mm" type="dateTime"
        v-model="endDateHourReturn" />
      <div class="text-right">
        <Button _key="btnEndDateHourReturn" type="primary" title="Aplicar" classIcon="fa-solid fa-circle-check"
          size="small" :clicked="editReturn" />
      </div>
    </Modal>
    <Modal title="Atenção" :width="500" v-show="showModal('isReturnBeforeAfter')">
      <Alert type="warning">
        <span>
          Locação retornada <b>FORA DO PRAZO PREVISTO</b>, verifique se há necessidade de ação...
        </span>
      </Alert>

      <div class="text-center div-isReturn">
        <Button _key="btnIsReturnBeforeAfter" type="primary" title="Ok, estou ciente" size="medium"
          :clicked="hideModalIsReturnBeforeAfter" />
      </div>
    </Modal>
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import InputTextEdit from "@nixweb/nixloc-ui/src/component/forms/InputTextEdit.vue";
import TableTotalRecords from "@nixweb/nixloc-ui/src/component/shared/TableTotalRecords.vue";
import Confirmation from "@nixweb/nixloc-ui/src/component/shared/Confirmation.vue";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import CheckboxSimple from "@nixweb/nixloc-ui/src/component/forms/CheckboxSimple.vue";

import DisplayCalculatePeriod from "@nixweb/nixloc-ui/src/component/rental/DisplayCalculatePeriod";
import SuccessMoviment from "../SuccessMoviment.vue";

import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CheckReturn",
  components: {
    Molded,
    Button,
    ScrollBar,
    Loading,
    Modal,
    Alert,
    CheckboxSimple,
    InputTextEdit,
    DisplayCalculatePeriod,
    TableTotalRecords,
    SuccessMoviment,
    Confirmation,
    DateTime,
    RadioGroup,
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      urlCheck: "/api/v1/moviment/generate-return/check-return",
      urlGenerate: "/api/v1/moviment/generate-return/generate",
      content: { items: [] },
      endDateHourCurrent: "",
      endDateHourReturn: "",
      loading: true,
      successMoviment: false,
      movimentId: "",
      isInvalid: true,
      periodSubtractRecalculate: false,
      periodAddRecurrent: false,
    };
  },
  mounted() {
    this.periodSubtractRecalculate =
      this.userLogged.parameterRental.periodSubtractRecalculate;
    this.periodAddRecurrent =
      this.userLogged.parameterRental.periodAddRecurrent;
    this.checkReturn();
  },
  computed: {
    ...mapGetters("generic", ["showModal"]),
    ...mapState("user", ["userLogged"]),
    ...mapGetters("user", ["hasRule"]),
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
    ...mapState("generateMoviment", ["periodRent", "productRent"]),
    isStockNegative() {
      return false;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["openModal", "removeLoading", "hideModal"]),
    ...mapMutations("generateMoviment", ["cleanProductSelected"]),
    checkReturn() {
      this.loading = true;
      let obj = {
        endDateHour: this.endDateHourCurrent,
        periodRent: this.periodRent.fullPeriod,
        productRent: this.productRent,
        productSelected: this.productSelectedByPeriod,
        isReturnBefore: this.content.isReturnBefore,
        isReturnAfter: this.content.isReturnAfter,
        periodAddRecurrent: this.periodAddRecurrent,
        periodSubtractRecalculate: this.periodSubtractRecalculate,
        periodRenewedRecalculate: this.periodRenewedRecalculate,
      };
      let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.content = response.content;
          this.endDateHourReturn = this.content.endDateHourReturn;
          this.isInvalid = false;

          if (this.content.isReturnBefore || this.content.isReturnAfter) {
            this.openModal("isReturnBeforeAfter");
          }

        } else {
          this.isInvalid = true;
        }
        this.loading = false;
      });
    },
    confirmDiscard() {
      this.openModal("discard");
    },
    discard() {
      this.hideModal();
      this.removeLoading(["confirm"]);
      this.endDateHourCurrent = this.content.endDateHourCurrent;
      this.checkReturn();
    },
    confirmReturn() {
      this.openModal("editReturn");
    },
    editReturn() {
      this.hideModal();
      this.removeLoading(["btnEndDateHourReturn"]);
      this.endDateHourCurrent = this.endDateHourReturn;
      this.checkReturn();
    },
    generate() {
      this.loading = true;
      this.content.rentId = this.rentId;
      let recalculate = {
        periodAddRecurrent: this.periodAddRecurrent,
        periodSubtractRecalculate: this.periodSubtractRecalculate,
      };

      let params = {
        url: this.urlGenerate,
        obj: { ...this.content, ...recalculate },
        notNotifyToast: false,
      };

      this.postApi(params).then((response) => {
        if (response.success) {
          this.movimentId = response.content;
          let self = this;
          setTimeout(function () {
            self.successMoviment = true;
            self.loading = false;
            self.cleanProductSelected();
            self.removeLoading(["btnGenerateOutput"]);
          }, 300);
        } else {
          this.removeLoading(["btnGenerateOutput"]);
        }
      });
    },
    recalculate() {
      this.checkReturn();
    },
    hideModalIsReturnBeforeAfter() {
      this.removeLoading(["btnIsReturnBeforeAfter"]);
      this.hideModal("isReturnBeforeAfter");
    }
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "timeLineAvailability") {
          this.openModal("timeLineAvailability");
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.td-description {
  max-width: 300px !important;
  word-wrap: break-word !important;
  font-size: 14px;
  color: #757d8c;
  font-style: italic;
}

.td-quantity {
  font-size: 16px;
}

.div-update {
  margin-left: 15px;
  height: 20px;
}

.edit {
  font-size: 15px;
  color: rgb(112, 112, 112);
  cursor: pointer;
}

.discard {
  font-size: 15px;
  color: red;
  cursor: pointer;
}

.div-title {
  margin-bottom: 10px;
}

.period {
  font-size: 15px;
}

.title {
  font-size: 15px;
}

.check {
  font-size: 30px;
}

.div-patrimony {
  background-color: #fafafc;
  margin-right: 14%;
}

.status-primary {
  color: blue !important;
  font-size: 20px;
}

.status-success {
  color: rgb(29, 158, 29);
  font-size: 20px;
}

.div-recalculate {
  margin-top: 18px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 20px;
}

.status-danger {
  color: red;
  font-size: 20px;
}

.value {
  font-size: 16px;
}

.icon-warning {
  color: darkorange;
}

.div-isReturn {
  margin-top: 25px;
  margin-bottom: 20px;
}
</style>