<template>
  <div>
    <RentAndSelected v-if="!isCheckReturn" titleProductRent="Locado" titleProductSelected="Retorno" :statusPatrimony="2"
      :rentId="rentId" />
    <CheckReturn v-if="isCheckReturn" :rentId="rentId">
      <Button v-show="isCheckReturn" _key="btnClickedBack" type="info" title="Voltar"
        classIcon="title fa-solid fa-circle-arrow-left" size="small" :clicked="backReturn" />
    </CheckReturn>
    <br />
    <div class="text-center">
      <Button v-if="!isCheckReturn" _key="btnClickedNext" type="info" title="Avançar"
        :disabled="productSelectedByPeriod.length == 0" classIcon="title fas fa-arrow-circle-right" size="medium"
        :clicked="checkReturn" />
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import RentAndSelected from "../RentAndSelected.vue";
import CheckReturn from "./CheckReturn.vue";

import { mapActions, mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "GenerateReturn",
  components: { RentAndSelected, Button, CheckReturn },
  props: {
    rentId: String,
  },
  data() {
    return {
      urlGet: "/api/v1/moviment/generate-return/get-all",
      periodRentId: "",
      isCheckReturn: false,
    };
  },
  computed: {
    ...mapState("generic", ["event"]),
    ...mapGetters("generateMoviment", ["productSelectedByPeriod"]),
  },
  mounted() {
    this.addType("return");
  },
  methods: {
    ...mapActions("generic", ["getApi"]),
    ...mapMutations("generateMoviment", [
      "addType",
      "addPeriodRent",
      "addProductRent",
    ]),
    ...mapMutations("generic", ["addLoading", "removeLoading"]),
    getAll() {
      this.addLoading("movimentProductRent");
      let params = {
        url: this.urlGet,
        obj: { rentId: this.rentId, periodRentId: this.periodRentId },
      };
      this.getApi(params).then((response) => {
        this.addProductRent(response.content);
      });
      let self = this;
      setTimeout(function () {
        self.removeLoading(["movimentProductRent"]);
      }, 300);
    },
    checkReturn() {
      this.isCheckReturn = true;
      this.removeLoading(["btnClickedNext"]);
    },
    backReturn() {
      this.isCheckReturn = false;
      this.removeLoading(["btnClickedBack"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "selectedPeriodRent") {
          this.periodRentId = event.data.period.id;
          this.addPeriodRent(event.data.period);
          this.getAll();
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.table th,
.table td {
  height: 10px !important;
  padding-left: 5px !important;
  padding-top: 7px !important;
  padding-bottom: 5px !important;
  padding-right: 5px !important;
  padding-left: 10px !important;
  border-bottom: 0px !important;
}

.title-header {
  font-size: 14px;
  color: #757d8c;
  font-weight: 400;
  text-transform: uppercase;
}

.title {
  font-size: 15px;
}

.status-success {
  color: darkgreen;
  font-size: 18px;
}

.status-warning {
  color: #f6bb2a;
  font-size: 18px;
}

.status-danger {
  color: red;
  font-size: 18px;
}
</style>